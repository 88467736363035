import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://web-api-sgp.piebarometre.digital/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  },
})

export default {
  getLineAGR (id) {
    return apiClient.get(`api/request-parametre-agr/get-ligne-parametrage/${id}`)
  },
  createLineAGR (LineAGR) {
    const formData = new FormData()
    formData.append('libelle_ligneparametrage_agrs', LineAGR.libelle_ligneparametrage_agrs)
    formData.append('id_parametrage_agr', LineAGR.id_parametrage_agr)
    formData.append('id_composants', LineAGR.id_composants)
    formData.append('type_valeur', LineAGR.type_valeur)
    return apiClient.post('api/request-parametre-agr/add-ligne-parametrage', formData)
  },

  updateLineAGR (LineAGR) {
    const formData = new FormData()
    formData.append('libelle_ligneparametrage_agrs', LineAGR.libelle_ligneparametrage_agrs)
    formData.append('id_parametrage_agr', LineAGR.id_parametrage_agr)
    formData.append('id_composants', LineAGR.id_composants)
    formData.append('type_valeur', LineAGR.type_valeur)
     return apiClient.post(`api/request-parametre-agr/update-ligne-parametrage/${LineAGR.id}`, formData)
  },

  deleteLineAGR (LineAGR) {
    return apiClient.post(`api/request-parametre-agr/delete-ligne-parametrage/${LineAGR.id}`)
 },
}
